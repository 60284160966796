import { useModal } from 'react-modal-hook';

import QuoteBox from 'components/content-blocks/customer-quote-box';
import Link from 'components/link/link';
import ScheduleDemoModal from 'components/modal/schedule-demo/schedule-demo-modal';
import SignupModal from 'components/modal/signups';
import VideoModal from 'components/modal/video';

type FranchiseInformationProps = {
  buttons: {
    signupModalLaunch: string
  },
  className: string,
  company_information: CompanyInformation,
  content: string,
  customer_review,
  franchise_wistia_id?: string,
  hubspot_meeting_url?: string,
  location: string,
  logos: string,
  name: string,
  schedule_demo_button_text: string,
  trial_length: string
}

type CompanyInformation = {
  logo: {
    source_url: string
  }
  resource_title?: string,
  resources: [],
  stats_number: string,
  stats_text: string
}

type Resources = {
  resource_link?: string
  resource_text: string,
  resource_url: {
    source_url: string
  }
}


const FranchiseInformation = ({
  name,
  location,
  content,
  customer_review,
  company_information,
  buttons,
  logos,
  hubspot_meeting_url,
  schedule_demo_button_text,
  className,
  trial_length,
  franchise_wistia_id,
}: FranchiseInformationProps) => {
  const [showScheduleDemoModal, hideScheduleDemoModal] = useModal(() => (
    <ScheduleDemoModal isOpen={ true } onHide={ hideScheduleDemoModal } customHubspotMeetingUrl={ hubspot_meeting_url } />
  ));
  const urlSwitch = (file: string | undefined | null, url: string | undefined | null) => {
    if (file && file !== '' && !file.includes('BLANK-PDF.pdf')) return file;
    if (url && url !== '' && url !== null) return url;
  };
  
  const buttonText = schedule_demo_button_text ? schedule_demo_button_text : 'Schedule Demo';

  const trialLength = parseInt(trial_length, 10) || undefined;
  
  return (
    <section
      className={ `franchise-information-section section ${className}` }
      data-section="franchise information"
    >
      <div className="container has-shadow-large">
        <div className="franchise-container columns is-multiline">
          <div className="company-information column is-two-thirds">
            <div className="company-logo is-hidden-tablet">
              <img
                className="logo"
                src={ company_information.logo.source_url }
                alt="franchise logo"
              />
            </div>
            <h2 className="headline">{name}</h2>
            <p className="company-location">{location}</p>
            <div className="company-stats is-hidden-tablet">
              <p>{company_information.stats_text}</p>
              <span>{company_information.stats_number}</span>
            </div>
            <p className="company-copy" dangerouslySetInnerHTML={ { __html: content } }></p>
            <ul
              className="company-logos"
              dangerouslySetInnerHTML={ { __html: logos } }
            />
            <QuoteBox { ...customer_review } className="reverse" no_image />
          </div>
          <div className="company-data column is-one-third">
            <div className="company-data-container">
              <div className="company-logo is-hidden-mobile">
                <img
                  className="logo"
                  src={ company_information.logo.source_url }
                  alt="franchise logo"
                />
              </div>

              <hr className="is-hidden-mobile" />
              <div className="company-stats is-hidden-mobile">
                <p>{company_information.stats_text}</p>
                <span>{company_information.stats_number}</span>
              </div>

              <hr className="is-hidden-mobile" />
              <h3 className="company-resource-title">
                {company_information.resource_title}
              </h3>
              <ul>
                {Object.values(company_information.resources).map(
                  (resource: Resources, index: number) => (
                    <li key={ index }>
                      {resource?.resource_text !== '' && <Link 
                        className="company-resource"
                        target="_blank"
                        to={ urlSwitch(resource?.resource_url?.source_url, resource?.resource_link) }
                      > {resource.resource_text}</Link>
                      }
                    </li>
                  ) 
                )}
              </ul>
            </div>
          </div>
          <div className="column is-full">
            <div className="buttons">
              <VideoModal
                hashedId={ franchise_wistia_id || '04bsil2tnw' }
                text="Watch Video"
                title="Watch Video"
                className="button is-rounded"
                autoPlay
              />
              <SignupModal
                modalTitle="Sign Up For Free"
                buttonClasses="button is-rounded"
                showInitFields={ false }
                uniqueId="signupsFooter"
                fieldLayout="row"
                buttonInline
                signupModalLaunch={ buttons.signupModalLaunch }
                showModalButton
                trialLength={ trialLength }
              />
              <button
                title="Talk to rep"
                type="button"
                className="button intercom-launch-demo is-rounded"
                onClick={ showScheduleDemoModal }
              >
                { buttonText }
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FranchiseInformation;