import { graphql, useStaticQuery } from 'gatsby';
import { kebabCase } from 'lodash';

import Layout from 'layouts/default';

import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import MobileFeatures from 'components/content-blocks/mobile-features';
import ProductVideo from 'components/content-blocks/product-video';
import Reviews from 'components/content-blocks/reviews';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';
import FranchiseInformation from 'components/templates/franchise-information';

import './styles/franchise.scss';

/*
This is a basic page template. 
In order for this template to work, don't create a file in /page that controls a page in wordpress.
If pages are created in gatsby then it will stop listening to this page template all together.
The following pages use the template:
- winmark
*/

export default function FranchiseTemplate({ pageContext }) {
  
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: { slug: { eq: "franchise-parent-page" } }) {
      edges {
        node {
          acf {
            franchise_information {
              layout
              trial_length
            }
            video_block_1 {
              image_desktop {
                source_url
              }
              image_mobile {
                source_url
              }
              video_id
            }
            features_grid {
              title
              sub_title
              buttons {
                title
                body
                alt_text
                icon_width
                icon_from_library
                mobile_screenshot {
                  source_url
                }
                desktop_screenshot {
                  source_url
                }
              }
            }
            features_grid_mobile_1 {
              title
              sub_title
              buttons {
                button_1 {
                  icon_from_library
                  icon_width
                  title
                  body
                  desktop_screenshot {
                    source_url
                  }
                  mobile_screenshot {
                    source_url
                  }
                }
                button_2 {
                  icon_from_library
                  icon_width
                  title
                  body
                  desktop_screenshot {
                    source_url
                  }
                  mobile_screenshot {
                    source_url
                  }
                }
                button_3 {
                  icon_from_library
                  icon_width
                  title
                  body
                  desktop_screenshot {
                    source_url
                  }
                  mobile_screenshot {
                    source_url
                  }
                }
                button_4 {
                  icon_from_library
                  icon_width
                  title
                  body
                  desktop_screenshot {
                    source_url
                  }
                  mobile_screenshot {
                    source_url
                  }
                }
              }
            }
            customer_feature_benefit {
              sub_title
              title
              copy
              customer {
                business
                name
                since
                photo_desktop {
                  source_url
                }
                photo_mobile {
                  source_url
                }
              }
              stats {
                value_1
                descriptor_1
                value_2
                descriptor_2
                value_3
                descriptor_3
              }
            }
            customer_quote_1 {
              company_info
              desktop_image {
                source_url
              }
              mobile_image {
                source_url
              }
              quote
            }
          }
        }
      }
    }
    reviews: allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          acf {
            reviews_section {
              title
              sub_title
              copy
            }
          }
        }
      }
    }
  }
`);

  const pageData = data.allWordpressPage.edges[0].node.acf;
  // if a video id is set for the franchise info, also show it in the video component
  const productVideo = {
    ...pageData.video_block_1,
    video_id: pageContext.acf.franchise_information.franchise_wistia_id || pageData.video_block_1.video_id,
  };
  const customerFeatureBenefit = pageData.customer_feature_benefit;
  const featuresGridMobile = pageData.features_grid_mobile_1;
  const featuresGrid = pageData.features_grid;
  const customerQuote = pageData.customer_quote_1;
  const reviews = data.reviews.edges[0].node.acf.reviews_section;
  const yoast = pageContext.yoast_head_json;
  const franchiseInformation = pageContext.acf.franchise_information;
  const mediaObject = pageContext.acf.media_object;
  const trialLength = pageContext.acf.franchise_information.trial_length;
  const layout = pageContext.acf.franchise_information.layout;
  const hero = pageContext.acf.hero;
  const cards1 = pageContext.acf.cards_1;

  // if you change class franchise-container make sure to change it in our schedule demo logic as well otherwise our small business franchise 3rd party calendar logic will break.
  return (
    <Layout
      customHubspotMeetingUrl={ franchiseInformation.hubspot_meeting_url }
      trialLength={ parseInt(trialLength, 10) || undefined }
    >
      <Yoast { ...yoast } canonical={ yoast && yoast?.canonical?.replace(/(\/blog|\/franchise-parent-page)/g, '')  } />
      <div
        className={ `franchise-container ${kebabCase(pageContext.slug)}-page-container` }
        data-signal={ pageContext.wordpress_id }
      >
        <HeroFeatures 
          { ...hero } 
          trialLength={ parseInt(trialLength, 10) || undefined } />
        {layout === 'pricing' && <HighlightCards { ...cards1 } hasTitle hasCopy hasImage />}

        <FranchiseInformation 
          { ...franchiseInformation }
          className={ mediaObject ? 'has-media-object' : '' } />
        { mediaObject && <MediaObject { ...mediaObject[0] } /> }
        {layout === 'none' && <HighlightCards { ...pageContext.acf.cards_1 } hasTitle hasCopy hasImage />}

        <ProductVideo { ...productVideo } />
        <CustomerFeatureBenefit { ...customerFeatureBenefit[0] } />
        <MobileFeatures { ...featuresGridMobile } />
        <CustomerFeatureBenefit
          { ...customerFeatureBenefit[1] }
          className="reverse"
        />
        <FeaturesGrid { ...featuresGrid[0] } className="reverse" />
        <QuoteBox { ...customerQuote } />
        <FeaturesGrid { ...featuresGrid[1] } />
        <Reviews { ...reviews } />

      </div>
    </Layout>
  );
}
